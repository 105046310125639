import React from 'react';
import { Code } from '@dnb/eufemia';
import { Ul, Li, P } from '@dnb/eufemia/elements';
import Head from '@dnb/helmet';
import { Link } from '@dnb/link';
import { SimpleHeader } from '@dnb/header';

/**
 * You can make the root-app "content independent".
 * The last micro app, defined in the plugins section inside gatsby-config.js
 * will take over, and provide this vert main index file.
 */
export default function Page() {
  const title = 'Web Framework Demo App';

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <SimpleHeader
        title={title}
        subtitle="Demonstration Gatsby App using Web Framework components"
      />
      <P>Available demos:</P>
      <Ul>
        <Li>
          <Link to="/error-boundary-demo">Error Boundary demos</Link>
        </Li>
        <Li>
          <Link to="/dynamic-routing-demo">Dynamic Routing demo</Link>
        </Li>
        <Li>
          <Link to="/translations">Translation demo</Link>
        </Li>
        <Li>
          <Link to="/feedback-form-demo">Feedback form demo</Link>
        </Li>
      </Ul>
      This gatsby app was generated using the default generator,{' '}
      <Code>nx g app framework/root-app</Code>
    </>
  );
}
